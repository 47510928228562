<h4 class="mb-3">{{ 'overview-faq-title' | cmsText | async }}</h4>

<div ngbAccordion #accordion="ngbAccordion" [closeOthers]="false">
    <div
        *ngFor="let item of faqItems; let i = index"
        #currentItem="ngbAccordionItem"
        ngbAccordionItem
    >
        <div ngbAccordionHeader>
            <button ngbAccordionButton class="d-flex text-start">
                <span>{{ itemTitleCmsKey(item) | cmsText | async }}</span>
            </button>
        </div>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <div>
                    <span
                        [innerHTML]="itemTextCmsKey(item) | cmsText | async"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</div>

import { AfterViewInit, Component } from '@angular/core';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { ExcludedReason, Registration } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { ExclusionService } from '@spnl/services/exclusion.service';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-excluded',
    templateUrl: './excluded.component.html',
})
export class ExcludedComponent {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    constructor(
        private registerStore: RegisterStoreService,
        private registerLocationService: RegisterLocationService,
        private stepService: RegisterStepService,
    ) {}

    revertExclusion(): void {
        this.stepService.revertExclusionClickedInSession = true;

        const excludedOnPersonStep =
            this.registration.excludedReason ===
            ExcludedReason.PostcodeOutOfRange;
        if (excludedOnPersonStep) {
            this.registerLocationService.goTo(
                RegisterStep.Person,
                new Substep.None(),
            );
        } else {
            const substep =
                ExclusionService.getRoofStepForExcludedReason(
                    this.registration.excludedReason,
                ) ?? RoofStep.Type;
            this.registerLocationService.goTo(
                RegisterStep.Product,
                substep ? new Substep.Exact(substep) : new Substep.First(),
            );
        }
    }

    get isMemberOfHomeOwnerAssociation(): boolean {
        return this.registration.excludedReason === ExcludedReason.MemberOfHomeOwnerAssociation;
    }
}

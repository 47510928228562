<h4 class="mb-3">
    {{ header$ | async }}
</h4>

<div
    ngbAccordion
    #accordion="ngbAccordion"
    [closeOthers]="false"
    *ngIf="faqItems$ | async as faqItems"
>
    <div
        *ngFor="let item of faqItems; let i = index"
        #currentItem="ngbAccordionItem"
        ngbAccordionItem
    >
        <div ngbAccordionHeader>
            <button ngbAccordionButton class="d-flex text-start">
                <span>{{ item | cmsText | async | faqheader }}</span>
            </button>
        </div>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <div>
                    <span
                        [innerHTML]="item | cmsText | async | faqcontent"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</div>
